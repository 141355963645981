<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5> RCS Template Settings</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="templateSettingList" v-model:selection="selectedProduct" :lazy="true"
                                :paginator="true" :rows="30" :totalRecords="totalRecords" :loading="loading"
                                @page="onPage($event)" class="p-datatable-users" data-key="tf1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:left>
                                        <h5 class="p-m-0">RCS Template Settings</h5>
                                    </template>
                                    <template v-slot:right>
                                        <Button label="Copy to other Admin  " icon="pi pi-copy" class="p-mr-2"
                                            @click="onSelectRCSSettings" style="margin: right 3.5rem;" />

                                        <Button label="Add RCS Template" icon="pi pi-plus" @click="onAddRCSTemplateSetting"
                                            style="margin: right 3.5rem;" />

                                        <!-- <Toast />
                                        <FileUpload mode="basic" name="demo[]" url="./upload.php" accept="*" :maxFileSize="1000000" @upload="onUpload" chooseLabel="Add Admin wTemplate"/> -->
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>

                                <Column selectionMode="multiple" headerStyle="width: 5%"></Column>
                                <!-- <Column field="id" header="Sr." headerStyle="width: 5%"> -->
                                <!-- <template #body="{ index }">
                                        {{ index+=1 }}
                                    </template>
                                </Column> -->
                                <Column field="name" header="Template Name" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text"> {{ data.tf3 }}</div>
                                    </template>
                                </Column>
                                <Column field="body" header="Template For" headerStyle="width: 25%">
                                    <template #body="{ data }">
                                        <!-- <div class="p-text-capitalize"> {{ data.tf34 }}</div> -->
                                        <div class="p-text-capitalize">
                                            <div class="p-text"> {{ data.tf22 }}</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Langauge" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div v-if="data.tf6 == 1"> English</div>
                                            <div v-else-if="data.tf6 == 2"> Marathi </div>
                                            <div v-else-if="data.tf6 == 3"> Kannad</div>
                                            <div v-else-if="data.tf6 == 4"> Tamil</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Vendor Id" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">{{ data.tf20 }}</div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditRCSTemplateSettings(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit wTemplate Dialog starts here -->
    <Dialog v-model:visible="isDialogOpen" :style="{ width: '800px' }" :modal="true" class="p-fluid" :header="dialogHeader">
        <div>
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="chatbotId">
                            ChatbotId
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="chatbotId" v-model="chatbotId" :options="chatbotList" optionLabel="label"
                            placeholder="Select ..." appendTo="body"></Dropdown>

                        <!-- <InputText id="chatbotId" class="p-text-capitalize" v-model.trim="chatbotId" required="true"
                            maxlength="20" autofocus :class="{ 'p-invalid': submitted && !chatbotId }" /> -->
                        <small class="p-invalid p-error" v-if="v$.chatbotId.$error">{{
                            v$.chatbotId.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="templateName">
                            Template Name
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="templateName" v-model.trim="templateName"
                            @input="isAlreadyPresentRCSTemplateName($event)" required="true" maxlength="20" autofocus
                            :class="{ 'p-invalid': submitted && !templateName }" />
                        <small class="p-invalid p-error" v-if="v$.templateName.$error">{{
                            v$.templateName.$errors[0].$message
                        }}</small>
                        <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg }}</small>

                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="shorttemplateName">
                            Template For
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="shorttemplateName" v-model="shorttemplateName" :options="shorttemplateNameList"
                            optionLabel="label" placeholder="Select ..." appendTo="body"></Dropdown>

                        <!-- <InputText id="shorttemplateName" class="p-text-capitalize" v-model.trim="shorttemplateName"
                            required="true" maxlength="100" /> 
                         <small class="p-invalid p-error" v-if="v$.shorttemplateName.$error">{{
                            v$.shorttemplateName.$errors[0].$message
                        }}</small>  -->
                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <label for="vendors_template_id">
                            Vendors template Id
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="vendors_template_id" v-model.trim="vendors_template_id" maxlength="20" />
                        <small class="p-invalid p-error" v-if="v$.vendors_template_id.$error">{{
                            v$.vendors_template_id.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                        <label for="templateBody">
                            Template Body
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Textarea id="templateBody" v-model="templateBody" :autoResize="true" rows="3" cols="30"
                            placeholder="enter template body" autofocus
                            :class="{ 'p-invalid': submitted && !templateBody }" />
                        <!-- <InputText id="templateBody" class="p-text-capitalize" v-model.trim="templateBody" required="true"
                            maxlength="20" autofocus :class="{ 'p-invalid': submitted && !templateBody }" /> -->
                        <small class="p-invalid p-error" v-if="v$.templateBody.$error">{{
                            v$.templateBody.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                        <label for="template_variables">
                            Template Variables
                            <!-- <span class="p-invalid p-error">*</span> -->
                        </label>
                        <Textarea id="template_variables" class="p-text-capitalize" :autoResize="true" rows="2" cols="30"
                            v-model.trim="template_variables" required="true" placeholder="enter template variables"
                            autofocus />
                        <!-- <small class="p-invalid p-error" v-if="v$.template_variables.$error">{{
                            v$.template_variables.$errors[0].$message
                        }}</small> -->
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="language">
                            Language
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="language" v-model="language" :options="languageList" optionLabel="label"
                            placeholder="Select ..." appendTo="body"></Dropdown>
                        <small class="p-invalid p-error" v-if="v$.language.$error">{{
                            v$.language.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="template_reply_type">
                            Reply Type
                            <!-- <span class="p-invalid p-error">*</span> -->
                        </label>
                        <Dropdown id="template_reply_type" v-model="template_reply_type" :options="templatereplytypeList"
                            optionLabel="label" placeholder="Select ..." appendTo="body">
                        </Dropdown>
                        <!-- <small class="p-invalid p-error" v-if="v$.template_reply_type.$error">{{
                            v$.template_reply_type.$errors[0].$message
                        }}</small> -->
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="next_template_name">
                            Next Template Name
                            <!-- <span class="p-i   nvalid p-error">*</span> -->
                        </label>
                        <Dropdown id="next_template_name" showClear v-model="next_template_name"
                            :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                        </Dropdown>
                        <!-- <small class="p-invalid p-error" v-if="v$.next_template_name.$error">{{
                            v$.next_template_name.$errors[0].$message
                        }}</small> -->
                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <label>Header status <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="headerstatusavl" name="option" v-bind:value="1" v-model="headerstatus"
                                :checked="headerstatus == 1" />
                            <label for="headerstatusavl" class="p-mr-2">Available</label>
                            <RadioButton id="headerstatusnotavl" name="option" v-bind:value="0" v-model="headerstatus"
                                :checked="headerstatus == 0" />
                            <label for="headerstatusnotavl" class="p-mr-2">Not Available</label>
                        </div>
                    </div>

                    <div class="p-field p-col-12 p-md-6" v-if="headerstatus == 1">
                        <label for="header_file_type">
                            header_file_type
                            <!-- <span class="p-invalid p-error">*</span> -->
                        </label>
                        <Dropdown id="header_file_type" showClear v-model="header_file_type" :options="headerFileTypeList"
                            optionLabel="label" placeholder="Select ..." @change="clearuploadimage()" appendTo="body">
                        </Dropdown>
                        <!-- <small class="p-invalid p-error" v-if="v$.header_file_type.$error">{{
                            v$.header_file_type.$errors[0].$message
                        }}</small> -->
                    </div>


                    <div class="p-field p-col-12 p-md-6" v-if="headerstatus == 1 && header_file_type">
                        <label>Upload Header <span class="p-invalid p-error">*</span></label>
                        <div v-if="header_file_type.value == 1">
                            <FileUpload v-model="header_file_url" mode="basic" ref="header_file_url" :maxFileSize="1000000"
                                accept="image/*" chooseLabel="Upload Header Image" auto @select="onSelectProfile">
                            </FileUpload>
                            <span v-if="header_file_url_edit && profileUrl == null">
                                <img class="p-pt-2" role="presentation" alt="edit" :src="header_file_url_edit" width="120"
                                    height="120" />
                            </span>
                        </div>
                        <div v-if="header_file_type.value == 2">
                            <FileUpload mode="basic" ref="videouploadref" class="custom-upload-btn-ultima" accept="pdf/*"
                                v-model="header_file_url" chooseLabel="Upload Header Document"
                                v-on:change="onSelectProfile()" />
                        </div>
                        <div v-if="header_file_type.value == 3">
                            <FileUpload mode="basic" ref="docuploadref" class="custom-upload-btn-ultima" accept="mp4/*"
                                v-model="header_file_url" chooseLabel="Upload Header Video"
                                v-on:change="onSelectProfile()" />
                        </div>
                        <div v-if="header_file_type.value == 4">
                            <FileUpload v-model="header_file_url" mode="basic" ref="tuneuploadeditref"
                                :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Header Tune" auto
                                @select="onSelectProfile" />
                        </div>
                        <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError }}</small>
                        <div>
                            <div v-if="header_file_type.value == 1">
                                <img v-if="profileUrl" class="p-pt-2" role="presentation" :alt="profileUrl.name"
                                    :src="profileUrl.url" width="120" height="120" />
                            </div>
                            <div v-if="header_file_type.value != 1">
                                <div class="p-field p-col-12 p-md-12" v-if="profileUrl != null">{{
                                    profileUrl.name
                                }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <label for="first_button_name">
                            First Button Name
                        </label>
                        <InputText id="first_button_name" class="p-text-capitalize" v-model.trim="first_button_name"
                            required="true" maxlength="20" />
                    </div>
                    <div class="p-field p-col-12 p-md-6" v-if="first_button_name">
                        <label for="first_button_template_name">
                            First Button Template Name

                            <span class="p-invalid p-error" v-if="first_button_name != ''">*</span>
                        </label>
                        <Dropdown id="first_button_template_name" showClear filter v-model="first_button_template_name"
                            :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                        </Dropdown>
                        <div v-if="first_button_name != ''">
                            <small class="p-invalid p-error" v-if="v$.first_button_template_name.$error">{{
                                v$.first_button_template_name.$errors[0].$message
                            }}</small>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="second_button_name">
                            Second Button Name
                        </label>
                        <InputText id="second_button_name" class="p-text-capitalize" v-model.trim="second_button_name"
                            maxlength="20" />
                    </div>

                    <div class="p-field p-col-12 p-md-6" v-if="second_button_name">
                        <label for="second_button_template_name">
                            Second Button Template Name
                            <span class="p-invalid p-error" v-if="second_button_name != ''">*</span>

                        </label>
                        <Dropdown id="second_button_template_name" showClear filter v-model="second_button_template_name"
                            :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                        </Dropdown>
                        <div v-if="second_button_name != ''">
                            <small class="p-invalid p-error" v-if="v$.second_button_template_name.$error">{{
                                v$.second_button_template_name.$errors[0].$message
                            }}</small>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="third_button_name">
                            Third Button Name
                        </label>
                        <InputText id="third_button_name" class="p-text-capitalize" v-model.trim="third_button_name"
                            maxlength="20" />
                    </div>
                    <div class="p-field p-col-12 p-md-6" v-if="third_button_name">
                        <label for="third_button_template_name">
                            Third Button Template Name
                            <span class="p-invalid p-error" v-if="third_button_name != ''">*</span>
                        </label>
                        <Dropdown id="third_button_template_name" showClear filter v-model="third_button_template_name"
                            :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                        </Dropdown>
                        <div v-if="third_button_name != ''">
                            <small class="p-invalid p-error" v-if="v$.third_button_template_name.$error">{{
                                v$.third_button_template_name.$errors[0].$message
                            }}</small>
                        </div>
                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <label for="fourth_button_name">
                            Fourth Button Name
                        </label>
                        <InputText id="fourth_button_name" class="p-text-capitalize" v-model.trim="fourth_button_name"
                            required="true" maxlength="20" />
                    </div>
                    <div class="p-field p-col-12 p-md-6" v-if="fourth_button_name">
                        <label for="fourth_button_template_name">
                            Fourth Button Template Name
                            <span class="p-invalid p-error" v-if="fourth_button_name != ''">*</span>
                        </label>
                        <Dropdown id="fourth_button_template_name" showClear filter v-model="fourth_button_template_name"
                            :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                        </Dropdown>
                        <div v-if="fourth_button_name != ''">
                            <small class="p-invalid p-error" v-if="v$.fourth_button_template_name.$error">{{
                                v$.fourth_button_template_name.$errors[0].$message
                            }}</small>
                        </div>
                    </div>



                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                class="p-button-text p-ml-auto" @click="addNewRCSTemplate" style="width: 90px;"></Button>
            <!-- <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button> -->
        </template>
    </Dialog>
    <!-- Add/Edit wTemplate Dialog ends here -->

    <!-- Copy Settings dialog start here -->
    <Dialog v-model:visible="isCopyRCSSetting" :style="{ width: '600px' }" header="Copy RCS Settings" :modal="true"
        class="p-fluid">
        <div class="p-field p-col-12 p-md-9">
            <label for="admin_name">
                Select Admin
            </label>
            <Dropdown v-model="admin_name" :options="adminList" optionLabel="label" appendTo="body"
                class="p-text-capitalize" placeholder="Select Admin" />
        </div>
        <div class="p-field p-col-12 p-md-9" v-if="selectedProduct == '' || selectedProduct == null">
            <label for="chatbot">
                Select chatbot
            </label>
            <Dropdown v-model="chatbot" :options="chatbotList" optionLabel="label" appendTo="body" class="p-text-capitalize"
                placeholder="Select chatbot" />

        </div>
        <template #footer>
            <Button v-if="!copysettingloader" label="Submit" icon="pi pi-check" class="p-button-text"
                :disabled="admin_name == null || admin_name == ''" @click="onCopySettingSubmit" style="width: 90px" />
            <Button v-show="copysettingloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- Copy Settings dialog end here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers, requiredIf } from '@vuelidate/validators';
// import moment from "moment";

export default {
    data() {
        return {
            templateSettingList: [],
            templateEventList: [],
            v$: useValidate(),
            isDialogOpen: false,
            // isSubmitted: false,
            showLoader: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: 0,
            isEditwTemplate: false,
            fromDate: null,
            toDate: null,
            todaysDate: new Date(),
            // wTemplateError: "",
            dialogHeader: "",
            validation: {},
            // wTemplateId: '',
            profileImageError: '',

            languageList: [],
            shorttemplateNameList: [],
            templatereplytypeList: [
                { label: 'Stop Chatbot', value: 0 },
                { label: 'Text', value: 1 },
                { label: 'Button', value: 2 },
                { label: 'Number Type', value: 3 },
                { label: 'aadhar number', value: 4 },
                { label: 'OTP', value: 5 },
                { label: 'Voter Card Number', value: 6 },
            ],

            chatbotList: [],
            chatbot: '',
            submitted: false,
            templateName: '',
            templateAction: '',
            templateEvent: '',
            templateBody: '',
            template_variables: '',
            language: '',
            template_reply_type: '',
            next_template_name: '',

            first_button_name: '',
            first_button_template_name: '',
            second_button_name: '',
            second_button_template_name: '',
            third_button_name: '',
            third_button_template_name: '',
            fourth_button_name: '',
            fourth_button_template_name: '',

            vendors_template_id: '',
            header_file_type: '',
            headerFileTypeList: [
                { label: 'Image', value: 1 },
                { label: 'Document', value: 2 },
                { label: 'Video', value: 3 },
                { label: 'Audio', value: 4 },
            ],
            shorttemplateName: '',
            headerstatus: 0,
            header_file_url: null,
            header_file_url_edit: null,
            chatbotId: '',
            profileUrl: null,
            allowedExtensions: '',
            docuploadref: '',
            videouploadref: '',
            tuneuploadeditref: '',
            file: '',
            row_id: 0,
            foundUniqueIdErrorMsg: '',

            isCopyRCSSetting: false,
            selectedProduct: null,
            copysettingloader: false,
            adminList: [],
            selectedRCSSettings: [],
            admin_name: '',
            localAdminName: '',
        };
    },
    validations() {
        return {
            chatbotId: { required: helpers.withMessage('Please select chatbotId', required) },
            language: { required: helpers.withMessage('Please select language', required) },
            templateName: { required: helpers.withMessage('Please enter template name', required) },
            //shorttemplateName: { required: helpers.withMessage('Please enter short template name', required) },
            templateBody: { required: helpers.withMessage('Please enter template body', required) },
            // template_reply_type: { required: helpers.withMessage('Please enter template reply type', required) },
            // header_file_type: { required: helpers.withMessage('please select Header File type', required) },
            // template_variables: { required: helpers.withMessage('Please enter template variables', required) },
            // next_template_name: { required: helpers.withMessage('Please enter next template name', required) },
            vendors_template_id: { required: helpers.withMessage('Please enter Vendor Template Id', required) },
            headerstatus: { required: helpers.withMessage('Please enter header status', required) },
            // header_file_url: {
            //     requiredIf: helpers.withMessage(
            //         "Please select header file",
            //         requiredIf(
            //             this.headerstatus == 1
            //         )
            //     ),
            // },
            first_button_template_name: {
                requiredIf: helpers.withMessage("Please select first button template",
                    requiredIf(
                        this.first_button_name != ''
                    )
                ),
            },
            second_button_template_name: {
                requiredIf: helpers.withMessage("Please select second button template",
                    requiredIf(
                        this.second_button_name != ''
                    )
                ),
            },
            third_button_template_name: {
                requiredIf: helpers.withMessage("Please select third button template",
                    requiredIf(
                        this.third_button_name != ''
                    )
                ),
            },
            fourth_button_template_name: {
                requiredIf: helpers.withMessage("Please select fourth button template",
                    requiredIf(
                        this.fourth_button_name != ''
                    )
                ),
            },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.loading = true;
        this.getRCSTemplateSettings({ clientId: this.routeParam });
        this.getTemplateDescriptions();
        this.getchatbotList();
    },
    methods: {
        getAllRCSTemplateOnly() {
            this.loading = true;
            this.ApiService.getAllRCSTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.data;
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },

        clearuploadimage() {
            this.profileImageError = '';
            this.header_file_url = null;
            this.profileUrl = '';
            this.header_file_url_edit = null;
        },

        isAlreadyPresentRCSTemplateName(event) {
            if (event.target.value.length > 4) {
                this.ApiService.isAlreadyPresentRCSTemplateName({ uniqueName: event.target.value, rowId: this.row_id, clientId: this.routeParam }).then((data) => {
                    if (data.status == 200) {
                        this.foundUniqueIdErrorMsg = '';
                        // this.foundUniqueId = data.data;
                        if (data.count > 0)
                            this.foundUniqueIdErrorMsg = "This template name already exist";
                        this.loading = false;
                    } else {
                        this.foundUniqueIdErrorMsg = '';
                        this.loading = false;
                    }
                    this.loading = false;
                });
            }
        },

        onSelectRCSSettings() {
            // this.copysettingloader= true;
            this.ApiService.getAdminListOnlyExceptGivenId({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data;
                } else {
                    this.adminList = null;
                }
            });
            if (this.selectedProduct == '' || this.selectedProduct == null) {
                this.getchatbotList();
            }
            this.isCopyRCSSetting = true;
        },

        getchatbotList() {
            this.ApiService.getchatbotList().then((data) => {
                if (data.status == 200) {
                    this.chatbotList = data.data;
                } else {
                    this.chatbotList = null;
                }
            });
        },

        onCopySettingSubmit() {
            this.copysettingloader = true;
            var formData = new FormData();
            if (this.selectedProduct) {
                let vals = this.selectedProduct.map(function (item) {
                    return item;
                });
                this.selectedRCSSettings = vals;
                formData.append("selectedRCSSetting", JSON.stringify(this.selectedRCSSettings));
            }
            formData.append("clientId", this.routeParam);
            formData.append("CopyIntoClientId", this.admin_name.value);
            // if (this.selectedRCSSettings)
            if (this.chatbot)
                formData.append("chatbotId", this.chatbot.value);

            //  for (var pair of formData.entries()) {
            //      console.log(pair[0] + ' - ' + pair[1]);
            //  }
            this.ApiService.copyRCSSettingToOtherClient(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.getRCSTemplateSettings({ clientId: this.routeParam });
                    this.copysettingloader = false;
                    this.isCopyRCSSetting = false;
                    this.admin_name = "";
                    this.chatbot = "";
                    this.selectedProduct = null;
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },

        getTemplateDescriptions() {
            this.loading = true;
            this.ApiService.getTemplateDescriptions().then((data) => {
                if (data.status == 200) {
                    this.shorttemplateNameList = data.data;
                } else {
                    this.shorttemplateNameList = '';
                }
                this.loading = false;
            });
        },

        async onSelectProfile(e) {
            console.log(e);
            console.log(this.header_file_type.value);
            if (this.header_file_type.value == 1) {
                this.allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.file = e.files[0];
            } else if (this.header_file_type.value == 2) {
                this.allowedExtensions = /(\.pdf)$/i;
                this.file = this.$refs.docuploadref.files[0];
            } else if (this.header_file_type.value == 3) {
                this.allowedExtensions = /(\.mp4|\.mpeg)$/i;
                this.file = this.$refs.videouploadref.files[0];
            } else if (this.header_file_type.value == 4) {
                this.allowedExtensions = /(\.mp3)$/i;
                this.file = this.$refs.tuneuploadeditref.files[0];
            }
            if (!this.file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.header_file_url = '';
            } else if (!this.file.name.match(this.allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.header_file_url = '';
            } else {
                this.profileImageError = '';
                if (this.header_file_type.value == 1) {
                    this.header_file_url = e.files[0];
                    this.profileUrl = { url: this.file.objectURL, name: this.file.name };
                }
                else {
                    this.profileUrl = { name: this.file.name };
                }
                // this.header_file_url = this.$refs.docuploadref.files[0];
                // this.header_file_url = this.$refs.tuneuploadeditref.files[0];
                // this.header_file_url = e.files[0];
            }
        },

        onAddRCSTemplateSetting() {
            this.v$.$reset();
            this.getAllRCSTemplateOnly();
            /** Open popup of add wTemplate gode goes here */
            this.dialogHeader = 'Add RCS Template';
            this.isDialogOpen = true;
            this.row_id = 0;
            this.templateName = '';
            this.templateAction = '';
            this.templateEvent = '';
            this.templateBody = '';
            this.template_variables = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';

            this.first_button_name = '';
            this.first_button_template_name = '';
            this.second_button_name = '';
            this.second_button_template_name = '';
            this.third_button_name = '';
            this.third_button_template_name = '';
            this.fourth_button_name = '';
            this.fourth_button_template_name = '';

            this.vendors_template_id = '';
            this.header_file_type = '';
            this.shorttemplateName = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.chatbotId = '';
            this.profileUrl = null;
            this.submitted = false;
        },

        onEditRCSTemplateSettings(e) {
            this.templateName = '';
            this.templateAction = '';
            this.templateEvent = '';
            this.templateBody = '';
            this.template_variables = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';

            this.first_button_name = '';
            this.first_button_template_name = '';
            this.second_button_name = '';
            this.second_button_template_name = '';
            this.third_button_name = '';
            this.third_button_template_name = '';
            this.fourth_button_name = '';
            this.fourth_button_template_name = '';
            this.vendors_template_id = '';
            this.header_file_type = '';
            this.shorttemplateName = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.chatbotId = '';
            this.profileUrl = null;
            this.submitted = false;
            // this.ApiService.getchatbotList().then((data) => {
            //     if (data.status == 200) {
            //         this.chatbotList = data.data;
            //         let ta23 = +this.wp_data.ta23;
            //         if (ta23) {
            //             let templateEventnextbtn = this.templateEventList.filter(function (item) {
            //                 return item.value == ta23;
            //             });
            //             if (templateEventnextbtn.length > 0) {
            //                 this.wp.next_template_name = templateEventnextbtn[0];
            //             }
            //         }
            //     }
            // }),
            this.ApiService.getAllRCSTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.data;
                    let templateEventnextbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf8;
                    });
                    if (templateEventnextbtn.length > 0) {
                        this.next_template_name = templateEventnextbtn[0];
                    }

                    let templateEventfirstbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf10;
                    });
                    if (templateEventfirstbtn.length > 0) {
                        this.first_button_template_name = templateEventfirstbtn[0];
                    }

                    let templateEventSecondbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf12;
                    });
                    if (templateEventSecondbtn.length > 0) {
                        this.second_button_template_name = templateEventSecondbtn[0];
                    }

                    let templateEventthirdbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf14;
                    });
                    if (templateEventthirdbtn.length > 0) {
                        this.third_button_template_name = templateEventthirdbtn[0];
                    }
                    let templateEventfourthbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf16;
                    });
                    if (templateEventfourthbtn.length > 0) {
                        this.fourth_button_template_name = templateEventfourthbtn[0];
                    }
                } else {
                    this.templateEventList = '';
                }
            });
            this.row_id = e.tf1;
            this.chatbotId = e.tf2;
            if (e.tf2 !== 0) {
                let chatbotListId = this.chatbotList.filter(function (item) {
                    return item.value == e.tf2;
                });
                if (chatbotListId.length > 0) {
                    this.chatbotId = chatbotListId[0];
                }
            }
            this.templateName = e.tf3;
            this.templateBody = e.tf4;
            this.template_variables = e.tf5;
            let languageListId = this.languageList.filter(function (item) {
                return item.value == e.tf6;
            });
            if (languageListId.length > 0) {
                this.language = languageListId[0];
            }

            this.shorttemplateName = '';
            if (e.tf23 != 0) {
                let shorttemplateNameListId = this.shorttemplateNameList.filter(function (item) {
                    return item.value == e.tf23;
                });
                if (shorttemplateNameListId.length > 0) {
                    this.shorttemplateName = shorttemplateNameListId[0];
                }
            }

            let template_reply_type_id = this.templatereplytypeList.filter(function (item) {
                return item.value == e.tf7;
            });
            if (template_reply_type_id.length > 0) {
                this.template_reply_type = template_reply_type_id[0];
            }

            let header_file_type_id = this.headerFileTypeList.filter(function (item) {
                return item.value == e.tf21;
            });
            if (header_file_type_id.length > 0) {
                this.header_file_type = header_file_type_id[0];
            }

            this.next_template_name = e.tf8;
            this.first_button_name = e.tf9;
            this.second_button_name = e.tf11;
            this.third_button_name = e.tf13;
            this.fourth_button_name = e.tf15;
            this.vendors_template_id = e.tf20;
            //this.shorttemplateName = e.tf22;
            this.headerstatus = e.tf17;
            if (e.tf18 != null || e.tf18 != '') {
                this.header_file_url_edit = e.tf18;
            }
            this.dialogHeader = "Edit RCS Template";
            this.isDialogOpen = true;
            this.submitted = false;
        },

        async getRCSTemplateSettings(params) {
            try {
                const result = await this.ApiService.getRCSTemplateSettings({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.templateSettingList = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
            }
        },

        async addNewRCSTemplate() {
            this.submitted = true;
            this.showLoader = true;
            this.v$.$validate();
            let formData = new FormData();
            formData.append("tf1", this.row_id);
            formData.append("tf2", this.chatbotId.value);
            formData.append('tf3', this.templateName);
            formData.append('tf4', this.templateBody);
            formData.append('tf5', this.template_variables);
            formData.append('tf6', this.language.value);
            if (this.template_reply_type)
                formData.append('tf7', this.template_reply_type.value);
            if (this.next_template_name) {
                formData.append('tf8', this.next_template_name.value);
            } else {
                formData.append('tf8', '');
            }
            formData.append('tf9', this.first_button_name);
            if (this.first_button_template_name !== '' && this.first_button_template_name !== null) {
                formData.append('tf10', this.first_button_template_name.value);
            } else {
                formData.append('tf10', '');
            }
            formData.append('tf11', this.second_button_name);
            if (this.second_button_template_name !== '' && this.second_button_template_name !== null) {
                formData.append('tf12', this.second_button_template_name.value);
            } else {
                formData.append('tf12', '');
            }
            formData.append('tf13', this.third_button_name);
            if (this.third_button_template_name !== '' && this.third_button_template_name !== null) {
                formData.append('tf14', this.third_button_template_name.value);
            }
            else {
                formData.append('tf14', '');
            }
            formData.append('tf15', this.fourth_button_name);
            if (this.fourth_button_template_name !== '' && this.fourth_button_template_name !== null) {
                formData.append('tf16', this.fourth_button_template_name.value);
            }
            else {
                formData.append('tf16', '');
            }
            formData.append('tf17', this.headerstatus);
            if (this.headerstatus == 1) {
                if (this.header_file_url != null) {
                    formData.append('tf18', this.header_file_url);
                    formData.append('tf21', this.header_file_type.value);
                }
            } else {
                formData.append('tf18', '');
                formData.append('tf21', 0);
            }

            formData.append('tf20', this.vendors_template_id);
            formData.append('tf22', this.shorttemplateName.label);
            formData.append('tf23', this.shorttemplateName.value);
            formData.append('clientId', this.routeParam);

            if (!this.v$.$error && this.foundUniqueIdErrorMsg == '') {
                let result = await this.ApiService.addUpdateClientRCSTemplates(formData)
                this.showLoader = false;
                if (result.success) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                    this.getRCSTemplateSettings({ clientId: this.routeParam });
                    this.wTemplateId = '';
                    this.isDialogOpen = false;
                    setTimeout(() => {
                        this.v$.$reset();
                    }, 0);
                } else {
                    this.isDialogOpen = false;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'RCS Template not added. Please try again', life: 3000 });
                }
            }
        },

        deletewTemplate() {
            /** Delete wTemplate code goes here */
        },

        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },
            });
        },

        goToPrevious() {
            router.push({
                name: "whatsapp-template-settings",
                params: { adminSubId: this.routeParam },
            });
        },
        
        goToNext() {
            router.push({
                name: "web-bot-template-settings",
                params: { adminSubId: this.routeParam },
            });
        },
    }

};
</script>

<style scoped></style>
